import cx from "classnames";
import Img from "gatsby-image";
import BgImage from "gatsby-background-image";
// @ts-ignore
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import React, { useState, useEffect } from "react";

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_PROJECTSET,
};

export const Image = ({
  imageId,
  className,
  imgClassName,
  width,
  alt,
  src,
  aspectRatio,
  imgStyle,
}: {
  imageId?: string;
  width?: number;
  alt?: string;
  className?: string;
  imgClassName?: string;
  src?: string;
  aspectRatio?: number;
  imgStyle?: any;
}) => {
  const [loaded, setLoaded] = useState(false);
  let fluidProps;

  if (imageId && !/gif/.test(imageId)) {
    fluidProps = getFluidGatsbyImage(
      imageId,
      { maxWidth: width || 2400 },
      sanityConfig
    );
  }

  const massagedFluidProps = aspectRatio
    ? { ...fluidProps, aspectRatio }
    : fluidProps;
  return (
    <div className={cx("image__block ma", className)}>
      {fluidProps ? (
        <Img
          fluid={massagedFluidProps}
          alt={alt}
          defaultFadeIn={700}
          imgStyle={imgStyle}
          className={imgClassName}
        />
      ) : (
        <img
          alt={alt}
          src={src ? src : undefined}
          className={cx("x y block", {
            "is-loaded": loaded,
          })}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      )}
    </div>
  );
};

export const BackgroundImage = ({
  imageId,
  className,
  width,
  alt,
  src,
  children,
  bgColor,
}: {
  imageId?: string;
  width?: number;
  className?: string;
  src?: string;
  children?: [];
  bgColor?: string;
}) => {
  const [loaded, setLoaded] = useState(false);
  let fluidProps;

  if (imageId && !/gif/.test(imageId)) {
    fluidProps = getFluidGatsbyImage(
      imageId,
      { maxWidth: width || 2400 },
      sanityConfig
    );
  }

  return (
    <>
      {fluidProps ? (
        <BgImage
          className={className}
          fluid={fluidProps}
          backgroundColor={bgColor}
          fadeIn={true}
        >
          {children}
        </BgImage>
      ) : (
        <div
          style={{
            backgroundImage: `url(${src ? src : undefined})`,
            backgroundColor: `${bgColor ? bgColor : undefined}`,
          }}
          className={cx(className, {
            "is-loaded": loaded,
          })}
          onLoad={() => {
            setLoaded(true);
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};
